import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'kit-general-10v1',
  templateUrl: './10v1.component.html',
  styleUrls: ['./10v1.component.scss'],
})
export class CuiGeneral10v1Component implements OnInit {
  constructor() {}
  ngOnInit() {}
}
