<ul class="list-unstyled">
  <li class="item">
    <div class="separator bg-success mr-3"></div>
    <label
      for="checkbox-1"
      class="control checkbox mb-0"
    >
      <input type="checkbox" checked id="checkbox-1"/>
      <span class="controlIndicator"></span>
      <div class="d-inline-block">
        <div>Payment Received</div>
        <div class="text-muted">From themeforest</div>
      </div>
    </label>
  </li>
  <li class="item">
    <div class="separator bg-primary mr-3"></div>
    <label
      for="checkbox-2"
      class="control checkbox mb-0"
    >
      <input type="checkbox" checked id="checkbox-2"/>
      <span class="controlIndicator d-block"></span>
      <div class="d-inline-block">
        <div>Payment Approved</div>
        <div class="text-muted">From themeforest</div>
      </div>
    </label>
  </li>
  <li class="item">
    <div class="separator mr-3"></div>
    <label
      for="checkbox-3"
      class="control checkbox mb-0"
    >
      <input type="checkbox" id="checkbox-3"/>
      <span class="controlIndicator d-block"></span>
      <div class="d-inline-block">
        <div>Payment Received</div>
        <div class="text-muted">From Paypal</div>
      </div>
    </label>
  </li>
  <li class="item">
    <div class="separator bg-danger mr-3"></div>
    <label
      for="checkbox-4"
      class="control checkbox mb-0"
    >
      <input type="checkbox" id="checkbox-4"/>
      <span class="controlIndicator d-block"></span>
      <div class="d-inline-block">
        <div>Withdrawal Failed</div>
        <div class="text-muted">From Bitcoin Address</div>
      </div>
    </label>
  </li>
  <li class="item">
    <div class="separator bg-info mr-3"></div>
    <label
      for="checkbox-5"
      class="control checkbox mb-0"
    >
      <input type="checkbox" id="checkbox-5"/>
      <span class="controlIndicator d-block"></span>
      <div class="d-inline-block">
        <div>Payment Received</div>
        <div class="text-muted">From themeforest</div>
      </div>
    </label>
  </li>
  <li class="item">
    <div class="mr-3 separator"></div>
    <label
      for="checkbox-6"
      class="control checkbox mb-0"
    >
      <input type="checkbox" id="checkbox-6"/>
      <span class="controlIndicator d-block"></span>
      <div class="d-inline-block">
        <div>Payment Received</div>
        <div class="text-muted">From themeforest</div>
      </div>
    </label>
  </li>
  <li class="item">
    <div class="separator mr-3"></div>
    <label
      for="checkbox-7"
      class="control checkbox mb-0"
    >
      <input type="checkbox" id="checkbox-7"/>
      <span class="controlIndicator d-block"></span>
      <div class="d-inline-block">
        <div>Payment Received</div>
        <div class="text-muted">From themeforest</div>
      </div>
    </label>
  </li>
</ul>
