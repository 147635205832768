import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'kit-general-23v1',
  templateUrl: './23v1.component.html',
})
export class CuiGeneral23v1Component implements OnInit {
  constructor() {}
  ngOnInit() {}
}
