import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import store from 'store'
import { Router } from '@angular/router'

@Injectable()
export class AuthService {
  public url: any;
  public _token: string;

  constructor(private http: HttpClient, private router: Router) {
    this.getUrl();
    
  }

  

  public async getUrl() {
    const urlserver: any = await this.getEndPoints();
    this.url = urlserver;
   }

  login(requestBody): Observable<any> {
    return this.http.post(`${this.url.auth}login`, requestBody)
  }


  guardarToken( access_token: string): void {
    localStorage.removeItem('token');
    localStorage.removeItem('usuario');
    localStorage.setItem('token', access_token);    

  }

  public get token(): string {
    if ( this._token != null ) {
      return this._token;
    } else if ( this._token == null && localStorage.getItem('token') != null) {
      this._token = localStorage.getItem('token');
      return this._token;
    }
  }


  isTokenExpired(): boolean {
    const token = localStorage.getItem('token');
    const payload = this.obtenerDatosToken(token);
    const now = new Date().getTime() / 1000;
    if (payload.exp < now) {
      return true;
    }
    return false;
  }

  obtenerDatosToken( access_token: string): any {
    if (access_token != null) {
      return JSON.parse(atob(access_token.split('.')[1]));
    }
    return null;
  }

  getEntity() {
    const entidad : any = localStorage.getItem('selected_entity');
    try {
      if( entidad ) {
        console.log('[Saved entity cahce]')
        return JSON.parse(entidad);
      } else {
        return null
      }
    } catch {
      localStorage.removeItem('selected_entity');
    }

  }

  isAuthenticated(): boolean {
    // tslint:disable-next-line: prefer-const
    let token = localStorage.getItem('token');
    const payload = this.obtenerDatosToken(token);
    if (payload != null && payload.email && payload.email.length > 0) {
      return true;
    }
    return false;
  }



  getEntidades(): Observable<any> {
    return this.http.get(`${this.url.auth}/entidades/list`);
  }

 /*  login(email: string, password: string): Observable<any> {
    return this.http.post('/api/auth/login', { email, password })
  } */

  register(email: string, password: string, name: string): Observable<any> {
    return this.http.post('/api/auth/register', { email, password, name })
  }

/*   currentAccount(): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            AccessToken: accessToken,
          },
        }
      : {}

    return this.http.get('/api/auth/account', params)
  } */

  async logout()  {

    const token = localStorage.getItem('token');
    const httpOptions = {headers: new HttpHeaders({  'Content-Type': 'application/json' , 'Authorization': 'Bearer ' + token})};
    localStorage.removeItem('token');
    localStorage.removeItem('usuario');
    this.router.navigate(['auth/login']);
 /*    await this.http.get(`${this.url.base}api/logout`,httpOptions).subscribe(
      (res) => {
    
      }
    ); */
  }


  public async getEndPoints(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const url = String(window.location.href).split('#')[0];
      this.http.get(`${url}assets/endpoints.json`)
      .subscribe((data: any) => {resolve(data); });
    });
  }
}
