import { Injectable } from '@angular/core'
import { Settings } from './app.settings.model'

@Injectable()
export class AppSettings {
  public endpoints: any

  public settings = new Settings('Cargando Recursos...', '(*)', {}, [], 0)

  constructor() {}
}
