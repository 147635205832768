<nz-layout>
  <nz-content>
    <cui-sidebar></cui-sidebar>
    <cui-support-chat></cui-support-chat>
    <div class="authContainer" [ngClass]="{
        'cui__layout__squaredBorders': isSquaredBorders,
        'cui__layout__cardsShadow': isCardShadow,
        'cui__layout__borderless': isBorderless,
        'white': authPagesColor === 'white',
        'gray': authPagesColor === 'gray'

      }" [ngStyle]="{backgroundImage: authPagesColor === 'image' ? 'url(assets/images/content/photos/7.jpg)' : ''}">
      <div class="topbar" [ngClass]="{
        'topbarGray': isGrayTopbar
      }">
        <div class="logoContainer">
          <div class="logo">
            <img src="assets/images/logo.png" class="mr-2" alt="COLIBRI PLATFORM" style="width: 35rem;" />                        
          </div>
        </div>
<!--         <div class="d-none d-sm-block">
          <span class="mr-2">No tienes una cuenta?</span>
          <a routerLink="/auth/register" class="font-size-16 kit__utils__link">
            Sign up
          </a>
        </div> -->
      </div>
      <div [ngClass]="settings.tipoAuth == 0 ? 'containerInner': 'containerInner2'">
        <div [@slideFadeinUp]="routeAnimation(outlet, 'slideFadeinUp')"
          [@slideFadeinRight]="routeAnimation(outlet, 'slideFadeinRight')"
          [@zoomFadein]="routeAnimation(outlet, 'zoomFadein')" [@fadein]="routeAnimation(outlet, 'fadein')">
          <router-outlet #outlet="outlet"></router-outlet>
        </div>
      </div>
      <div class="mt-auto pb-5 pt-5">
        <ul class="footerNav list-unstyled d-flex mb-0 flex-wrap justify-content-center">
          <li class="list-inline-item">
            <a href="javascript: void(0);">Terminos de uso</a>
          </li>
          <li class="active list-inline-item">
            <a href="javascript: void(0);">Conformidad</a>
          </li>
          <li class="list-inline-item">
            <a href="javascript: void(0);">Soporte</a>
          </li>
          <li class="list-inline-item">
            <a href="javascript: void(0);">Contactanos</a>
          </li>
        </ul>
        <div class="text-center">
          Copyright © 2023 Colibri Platform |
          <a href="https://www.mediatec.org/privacy" target="_blank" rel="noopener noreferrer">
            Politicas de privacidad 
          </a>
        </div>
      </div>
    </div>
  </nz-content>
</nz-layout>