import { Component } from '@angular/core'
import { select, Store } from '@ngrx/store'
import * as Reducers from 'src/app/store/reducers'
import { AuthService } from '@authService'

@Component({
  selector: 'cui-topbar-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class TopbarUserMenuComponent {
  badgeCount: number = 7
  name: string = ''
  role: string = ''
  email: string = ''
  phone: string = ''

  constructor(private store: Store<any>, private auth: AuthService) {
    const user = this.auth.obtenerDatosToken(localStorage.getItem('token'));
    this.name = user.nombreCompleto;
    this.role = user.role;
    this.email = user.email;
    this.phone = user.celular;

  /*   this.store.pipe(select(Reducers.getUser)).subscribe(state => {
      this.name = state.name
      this.role = state.role
      this.email = state.email
    }) */
  }

  badgeCountIncrease() {
    this.badgeCount = this.badgeCount + 1
  }

  logout() {
    this.auth.logout();
    //this.store.dispatch(new UserActions.Logout())
  }
}
