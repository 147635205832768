import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'kit-list-13',
  templateUrl: './13.component.html',
  styleUrls: ['./13.component.scss'],
})
export class CuiList13Component implements OnInit {
  constructor() {}
  ngOnInit() {}
}
