<div>
  <ul class="list-unstyled">
    <li class="item">
      <div class="itemDots" style="background-image: url('/assets/images/3-rounds.png')"></div>
      <div class="itemPicContainer">
        <div class="itemPic bg-success"></div>
        <i class="itemIcon text-success fe fe-file-text"></i>
      </div>
      <div>
        <div>
          <strong class="text-primary">Bitcoin</strong> lorem Ipsum is simply dummy text
          of the printing and...
        </div>
        <div class="text-muted">Deposited</div>
      </div>
    </li>
    <li class="item">
      <div class="itemDots" style="background-image: url('assets/images/3-rounds.png')"></div>
      <div class="itemPicContainer">
        <div class="itemPic bg-info"></div>
        <i class="itemIcon text-info fe fe-mail"></i>
      </div>
      <div>
        <div>
          <strong class="text-primary">Litecoint</strong> lorem Ipsum is simply dummy text
          of the printing and...
        </div>
        <div class="text-muted">Deposited by PayPal</div>
      </div>
    </li>
    <li class="item">
      <div class="itemDots" style="background-image: url('assets/images/3-rounds.png')"></div>
      <div class="itemPicContainer">
        <div class="itemPic bg-danger"></div>
        <i class="itemIcon text-danger fe fe-grid"></i>
      </div>
      <div>
        <div>
          <strong>Dash</strong> lorem Ipsum is simply dummy text of the printing and...
        </div>
        <div class="text-muted">To Dash adress</div>
      </div>
    </li>
    <li class="item">
      <div class="itemDots" style="background-image: url('assets/images/3-rounds.png')"></div>
      <div class="itemPicContainer">
        <div class="itemPic bg-primary"></div>
        <i class="itemIcon text-primary fe fe-database"></i>
      </div>
      <div>
        <div>
          <strong>Bitcoin</strong> lorem Ipsum is simply dummy text of the printing and...
        </div>
        <div class="text-muted">Deposited</div>
      </div>
    </li>
    <li class="item">
      <div class="itemDots" style="background-image: url('assets/images/3-rounds.png')"></div>
      <div class="itemPicContainer">
        <div class="itemPic bg-success"></div>
        <i class="itemIcon text-success fe fe-flag"></i>
      </div>
      <div>
        <div>
          <strong>Litecoin</strong> lorem Ipsum is simply dummy text of the printing and...
        </div>
        <div class="text-muted">Deposited by PayPal</div>
      </div>
    </li>
  </ul>
</div>
