<ul class="list-unstyled">
  <li class="item">
    <div class="itemTime mr-3">16:00</div>
    <div class="itemSeparator">
      <div class="donut danger mr-3"></div>
    </div>
    <div>
      Lorem ipsum dolor sit amit,consectetur eiusmdd tempor incididunt ut labore et dolore
      magna elit enim at minim veniam quis nostrud
    </div>
  </li>
  <li class="item">
    <div class="itemTime mr-3">15:28</div>
    <div class="itemSeparator">
      <div class="donut danger mr-3"></div>
    </div>
    <div>David Beckham was registered as administrator</div>
  </li>
  <li class="item">
    <div class="itemTime mr-3">14:26</div>
    <div class="itemSeparator">
      <div class="donut danger mr-3"></div>
    </div>
    <div>
      Lorem ipsum dolor sit amit,consectetur eiusmdd tempor incididunt ut labore et dolore
    </div>
  </li>
  <li class="item">
    <div class="itemTime mr-3">13:22</div>
    <div class="itemSeparator">
      <div class="donut danger mr-3"></div>
    </div>
    <div>
      Lorem ipsum dolor sit amit,consectetur eiusmdd tempor incididunt ut labore et dolore
      magna elit enim at minim veniam quis nostrud
    </div>
  </li>
</ul>
