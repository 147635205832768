import { Injectable } from '@angular/core'
import { AuthService } from '@authService'
import { Observable, of } from 'rxjs'
import { getMenuDataAdmin } from './config'
import { getMenuDataUser } from './config'

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  constructor(private auth: AuthService) {}

  getMenuData(): Observable<any[]> {

    const rol = this.auth.obtenerDatosToken(localStorage.getItem('token')).role;
    console.log('ROL', rol);
    if (rol) {
      if (rol == 'ADMIN') {
        return of(getMenuDataAdmin);
      }
    }
    return of(getMenuDataUser);
  }
}
