import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'kit-list-8',
  templateUrl: './8.component.html',
  styleUrls: ['./8.component.scss'],
})
export class CuiList8Component implements OnInit {
  constructor() {}
  ngOnInit() {}
}
