import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'kit-general-2v3',
  templateUrl: './2v3.component.html',
})
export class CuiGeneral2v3Component implements OnInit {
  constructor() {}
  ngOnInit() {}
}
