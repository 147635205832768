import {
HttpInterceptor,
HttpHandler,
HttpRequest,
HttpEvent,
HttpResponse,
HttpErrorResponse,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { tap, catchError } from 'rxjs/operators'
import { NzNotificationService } from 'ng-zorro-antd'

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
constructor(private notification: NzNotificationService) {}

public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // open spinner

    return next.handle(req).pipe(
    tap(evt => {
        if (evt instanceof HttpResponse) {
        /*                     if (evt.body && evt.body.success) {
                        this.toasterService.success
                            (evt.body.success.message, evt.body.success.title, { positionClass: 'toast-bottom-center' })
                            this._ApiRestService.showSuccess('Peticion Realizada');
                            this._ApiRestService.closeSpinner();
                    } */
          //close spinner
        }
    }),
    catchError((error: any) => {
        if (error.error instanceof ErrorEvent) {
        this.notification.error('Error al consultar', error.error.message)
          //close spinner
        } else {
        switch (error.status) {
            case 401: // login
            this.notification.error('NO AUTORIZADO!', error.error.message)
              //this._ApiRestService.goTo('/auth');
            break
            case 403:
            this.notification.error('PROHIBIDO!', error.error.message)
            break
            case 404:
            this.notification.error('RECURSO NO ENCONTRADO!', error.error.message)
            break
            case 405:
            this.notification.error('NO PERMITIDO!', error.error.message)
            break
            case 408:
            this.notification.error('SE AGOTO EL TIEMPO DE ESPERA!', error.error.message)
            break
            case 415:
            console.log(error)
            this.notification.error('FORMATO NO SOPORTADO!', error.error.message)
            break
            case 500:
            console.log(error)

            this.notification.error('ERROR EN EL SERVIDOR!', error.error.message)
            break
            case 501:
            this.notification.error('CONSULTA NO SOPORTADA!', error.error.message)
            break
        }
          //close spinner
        }
        return of(error)
    }),
    )
}
}
