import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { TranslateModule } from '@ngx-translate/core'
import { PipesModule } from './components/pipes/pipes.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import {MatDialogModule} from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
/* import {FullCalendarModule} from 'primeng/fullcalendar';
 */
import { ChartistModule } from 'ng-chartist'
import { ChartModule } from 'angular2-chartjs'


// basic acl
import { ACLComponent } from 'src/app/components/cleanui/system/ACL/acl.component'

// antd components module
import { AntdModule } from 'src/app/antd.module'

const MODULES = [CommonModule, RouterModule, AntdModule, ChartistModule, ChartModule,
  FormsModule, /* FullCalendarModule, */ 

  ReactiveFormsModule, TranslateModule, PipesModule, PerfectScrollbarModule,  MatDialogModule ]

@NgModule({
  imports: [...MODULES],
  declarations: [ACLComponent],
  exports: [...MODULES],
})
export class SharedModule {}
