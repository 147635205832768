import { Injectable, OnInit } from '@angular/core'
 import { HttpClient, HttpHeaders } from '@angular/common/http'
 import { Router } from '@angular/router'
import { Settings } from '../app.settings.model'
import { AppSettings } from '../app.settings'
import * as saveAs from 'file-saver';
import { PerfilEntidad } from 'src/app/models/PerfilEntidad'
import { DomSanitizer } from '@angular/platform-browser'

@Injectable()
export class ApiService {
  private perfilEntidad: PerfilEntidad = {} ;
  private imageLogo;
  public url: any
  private httpHeaders = new HttpHeaders({ 'Content-type': 'application/json' })
  private httpHeaders2 = new HttpHeaders('multipart/*')
  public settings: Settings

  constructor(public appSettings: AppSettings, private http: HttpClient, private router: Router,private sanitizer:DomSanitizer) {
    this.getUrl()
    this.settings = this.appSettings.settings
  }

  async getPerfilEntidad(){    
    let perfilEntidad = await this.get('perfil_entidad/list') 
    this.perfilEntidad = perfilEntidad[0];
     //this.loadImagen(this.perfilEntidad.logo);
  }

  get _perfilEntidad(){
    return this.perfilEntidad;
  }
 
  public agregarAuthorizationHeader2() {
    const token = localStorage.getItem('token')
    if (token != null && token !== '' && token !== undefined) {
    return this.httpHeaders2.append('Authorization', 'Bearer ' + token)
    } else {
    localStorage.removeItem('token');
    localStorage.removeItem('usuario');
    this.router.navigate(['auth/login']);
    }
    return this.httpHeaders2
}


  public agregarAuthorizationHeader() {
    const token = localStorage.getItem('token')
    if (token != null && token !== '' && token !== undefined) {
      return this.httpHeaders.append('Authorization', 'Bearer ' + token)
    } else {
      localStorage.removeItem('token')
      localStorage.removeItem('usuario')
      this.router.navigate(['auth/login'])
    }
    return this.httpHeaders
  }

  public agregarAuthorizationHeaderFile(): HttpHeaders {
    const token = localStorage.getItem('token')
    if (token != null && token !== '' && token !== undefined) {
      return new HttpHeaders({ 'Authorization': 'Bearer ' + token})
    } else {
      localStorage.removeItem('token')
      localStorage.removeItem('usuario')
      this.router.navigate(['auth/login'])
      return this.httpHeaders
    }
  }

  public async getUrl() {
    const urlserver: any = await this.getEndPoints()
    this.url = urlserver;
  }

  public async _GET(ruta: string): Promise<any> {
    const urlserver: any = await this.getEndPoints()
    const url = urlserver.auth
    const httpHeaders = new HttpHeaders({ 'Content-type': 'application/json' })
    return await this.http
      .get<any>(`${url}${ruta}`, { headers: httpHeaders })
      .toPromise()
  }

  public async _POST(ruta: string, body: any): Promise<any> {
    const urlserver: any = await this.getEndPoints()
    const url = urlserver.auth
    const httpHeaders = new HttpHeaders({ 'Content-type': 'application/json' })
    return await this.http
      .post<any>(`${url}${ruta}`, body, { headers: httpHeaders })
      .toPromise()
  }

  public async get(ruta: string): Promise<any> {
    return await this.http
      .get<any>(`${this.url.default}${ruta}`, { headers: this.agregarAuthorizationHeader() })
      .toPromise()
  }

  public post(ruta: string, body: any, multipart?) {
    let headers;
    if (multipart) {
       headers = this.agregarAuthorizationHeader2();
    } else {
        headers = this.agregarAuthorizationHeader();
    }
    console.log(headers);
    return this.http.post(`${this.url.default}${ruta}`, body, { headers: headers });
}
      
    public postFile(ruta: string, body: FormData) {
      return this.http.post(`${this.url.default}${ruta}`, body, { headers: this.agregarAuthorizationHeaderFile() });
    }
    

  public delete(ruta: string) {
    return this.http.delete(`${this.url.default}${ruta}`, {
      headers: this.agregarAuthorizationHeader(),
    })
  }

  public put(ruta: string, body: any) {
    return this.http.put(`${this.url.default}${ruta}`, body, {
      headers: this.agregarAuthorizationHeader(),
    })
  }

  public async getEndPoints(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const url = String(window.location.href).split('#')[0]
      this.http.get(`${url}assets/endpoints.json`).subscribe((data: any) => {
        resolve(data)
      })
    })
  }

  public async imprimirPDF(ruta: string) {
    this.http
      .get(`${this.url.default}${ruta}`, {
        responseType: `arraybuffer`,
        headers: this.agregarAuthorizationHeader(),
      })
      .subscribe(
        (data: any) => {
          const file = new Blob([data], { type: `application/pdf` })
          const fileURL = URL.createObjectURL(file)
          window.open(fileURL)
        },
        error => {
          console.log('error al consultar reporte',error)
        },
      )
  }

  public async imprimirExcel(ruta: string) {
    this.http.get(`${this.url.default}${ruta}`, {
      observe: 'response',
      responseType: 'blob',
      headers: this.agregarAuthorizationHeader(),
    })
      .subscribe(
        (data: any) => {;
          let file = new Blob([data.body], { type: 'application/vnd.ms-excel;charset=utf-8' });
          saveAs(file,"Inventario.xlsx")
        },
        error => {
          console.log('error al consultar reporte',error)
        },
      )
  }
  
  /*public async loadImagen(nombreImagen: string){
    this.http.get(`${this.url.default}uploads/img/${nombreImagen}`, {
      observe: 'response',
      responseType: 'blob',
      headers: this.agregarAuthorizationHeader(),
    }).subscribe((data =>{
      let objectURL = URL.createObjectURL(data.body);       
      this.imageLogo = this.sanitizer.bypassSecurityTrustUrl(objectURL);
    }),
    error => {
      console.log('error al consultar imagen',error)
    },)
  }*/

  public changeAuthTipo(tipo: number): void {
    this.settings.tipoAuth = tipo
  }

  public getAuthTipo(): number {
    return this.settings.tipoAuth
  }
}
