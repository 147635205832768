import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'kit-list-19',
  templateUrl: './19.component.html',
  styleUrls: ['./19.component.scss'],
})
export class CuiList19Component implements OnInit {
  constructor() {}
  ngOnInit() {}
}
