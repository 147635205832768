<div class="footer">
  <div class="footerInner">
    <a href="https://sellpixels.com" target="_blank" rel="noopener noreferrer" class="logo">
      Diligenciamiento de Encuestas
      <span></span>
    </a>
    <br />
    <p class="mb-0">
      Copyright © 2023 COLIBRI Platform |
      <a href="https://www.mediatec.org/privacy" target="_blank" rel="noopener noreferrer">Terminos de uso</a>
    </p>
  </div>
</div>