import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router'
import {Injectable, OnInit} from '@angular/core';
import { AuthService } from '@authService';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, OnInit {

  constructor( private router: Router, private auth: AuthService) {
  }

  public usuario: any;


  ngOnInit() {
    this.usuario = JSON.parse(sessionStorage.getItem('usuario'));
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (this.auth.isAuthenticated()) {
      if (this.isTokenExpired()) {
        this.auth.logout();
        this.router.navigate(['auth/login']);
        return false;
      }
        return true;
    }
    this.router.navigate(['auth/login'])
    return false;
  }






  isTokenExpired(): boolean {
    const token = localStorage.getItem('token');
    const payload = this.auth.obtenerDatosToken(token);
    const now = new Date().getTime() / 1000;
    if (payload.exp < now) {
      return true;
    }
    return false;
  }
}


