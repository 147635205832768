import { Injectable, OnInit } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import store from 'store'

@Injectable()
export class PublicService implements OnInit {
public url: any;
private httpHeaders = new HttpHeaders({ 'Content-type': 'application/json' });

constructor(private http: HttpClient) {this.getUrl();}
    ngOnInit(): void {
    }


public async getUrl() {
    const urlserver: any = await this.getEndPoints();
    this.url = urlserver.auth;
}

async getEntidades() {    
    return await this.http.get(`${this.url}entidades/list`, {headers: this.httpHeaders}).toPromise();
}


public async getEndPoints(): Promise<any> {
    return new Promise(async (resolve, reject) => {
    const url = String(window.location.href).split('#')[0];
    this.http.get(`${url}assets/endpoints.json`)
    .subscribe((data: any) => {resolve(data); });
    });
}
}
