import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'kit-list-17',
  templateUrl: './17.component.html',
  styleUrls: ['./17.component.scss'],
})
export class CuiList17Component implements OnInit {
  constructor() {}
  ngOnInit() {}
}
