import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'kit-list-12',
  templateUrl: './12.component.html',
  styleUrls: ['./12.component.scss'],
})
export class CuiList12Component implements OnInit {
  constructor() {}
  ngOnInit() {}
}
