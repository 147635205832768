import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'kit-general-22',
  templateUrl: './22.component.html',
  styleUrls: ['./22.component.scss'],
})
export class CuiGeneral22Component implements OnInit {
  constructor() {}
  ngOnInit() {}
}
